import React, { FC } from 'react'
import s from './Selection.module.sass'
import { Template } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'
import { TemplateCardProps } from '@widgets/Restaurant/ResturantCards/RestCard/type/TemplateCardProps'
import TypeAndPrice from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TypeAndPrice/TypeAndPrice'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
import { PromoBadgeLabel } from '@shared/ui/Feedback/PromoBadges/PromoBadgeLabel'

const Selection: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = (props) => {
  const price = props.data.price

  return (
    <ErrorBoundary>
      <Template {...props}>
        {!!props.data.discounts.remarked && (
          <PromoBadgeLabel label={'Скидка'} className={s['promo-label']}>
            -{props.data.discounts.remarked}₽
          </PromoBadgeLabel>
        )}
        {/*<TastyClub tastyClub={props.data.discounts.tastyclub} />*/}
        <TypeAndPrice
          restType={props.data.type}
          tag={props.data?.tags?.main_tag}
          priceRange={price.level}
          priceLink={price.tag.url}
        />

        <Text sizes={'S'}>{props.data.description.title}</Text>
      </Template>
    </ErrorBoundary>
  )
}

export default Selection
