/**
 * @fsd_page NewsItem - страница одной новости
 */

import s from './NewsItem.module.sass'
import React, { useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useAppSelector } from '@app/model/store'
import { NewsCard, NewsCardSkeleton } from '@widgets/News/NewsCard'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { Avatar } from '@shared/ui/Media/Avatar'
// @ts-ignore
import AvatarMargo from '@images/special-margo-1.jpeg'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { Cover, CoverSkeleton } from '@shared/ui/Layout/Cover'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import generateNewsTitle from '@shared/lib/helpers/newsTitle'

// @ts-ignore
import IconTelegram from '@icons/social-tg.svg'
// @ts-ignore
import IconVK from '@icons/social-vk.svg'
// @ts-ignore
import IconDzen from '@icons/social-zn.svg'
// @ts-ignore
import IconQuote from '@icons/icon-quote.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import { EGGNewsItem } from '@shared/api/analytics/requests/pages/NewsItem'
import { EGG } from '@shared/api/analytics'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { NewsAuthor } from '@pages/NewsItem/ui/NewsAuthor'
import { NewsContent } from '@pages/NewsItem/ui/NewsContent'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { DailyCarousel } from '@widgets/Common/Daily/DailyCarousel'

const NewsItem = ({ isStoreLoaded }) => {
  const stateGlobal = useAppSelector((store) => store.global)
  const { news, restaurants, others } = useAppSelector((store) => store.pages.news_item)
  const { isMobile, isTablet } = useClientResponsive()

  useEffect(() => {
    if (news?.id) EGG.pages.NewsItem.appear_screen_page()
  }, [news?.id])

  const imgAlt = news?.description.title ? `Фото новости от Афиши — ${news.description.title}` : `Фото новости от Афиши`
  const fullNewsDate = news ? `${news.date} ${new Date(news.dateUNIX).getFullYear()}` : ''

  const defaultCoverImage =
    news?.img.sizes.cover_2210x1244 ||
    news?.img.sizes.cover_1220x594 ||
    news?.img.sizes.cover_594x594 ||
    news?.img.sizes.desktop['609x303'] ||
    ''

  const isShowOthersNews = !news || (news && !!others?.length)
  const isShowRestaurantList = !news || (news && !!restaurants?.length)

  return (
    <ErrorBoundary>
      {isStoreLoaded ? (
        <main className={s.wrapper}>
          {news ? (
            <Cover img={defaultCoverImage}>
              {/*@ts-ignore*/}
              <Cover.Main alt={imgAlt} className={s['cover-main']}>
                <Text tag={'span'} sizes={'M S'} className={s.date}>
                  {fullNewsDate}
                </Text>

                <Title tag={'h1'} sizes={'h1 h3'} className={s.title}>
                  {generateNewsTitle(news.description.title)}
                </Title>

                <div className={s['cover-additional']}>
                  <Text tag={'span'} sizes={'M S'} className={s['type-news']}>
                    {news.type.name}
                  </Text>

                  <Text tag={'span'} sizes={'M S'} className={s['reading-time']}>
                    5 минут на чтение
                  </Text>
                </div>
                {/*@ts-ignore*/}
              </Cover.Main>

              {/*@ts-ignore*/}
              <Cover.Aside alt={imgAlt}></Cover.Aside>
            </Cover>
          ) : (
            <CoverSkeleton />
          )}

          <ContainerTwoColumns>
            {/*@ts-ignore*/}
            <ContainerTwoColumns.Main>
              <NewsAuthor className={s.author} />

              <NewsContent className={s.content} />

              {isShowRestaurantList &&
                (isTablet || isMobile ? (
                  <SectionCarousel countSlidesOnContainer={2} title={'В этой статье'} skeleton={!news}>
                    <SectionCarousel.Items>
                      {restaurants?.map((item, index) => (
                        <RestCard
                          key={item.id}
                          data={item}
                          sizes={'L ML'}
                          type={'list'}
                          analytic={{
                            advertCardPlaceType: null,
                            // @ts-ignore
                            section_name: `Ресторан из статьи`,
                            section_index: index,
                          }}
                        />
                      ))}
                    </SectionCarousel.Items>
                    <SectionCarousel.Skeletons>
                      {Array.from(Array(4).keys()).map((i, idx) => (
                        <RestCardSkeleton key={idx} sizes={'L ML'} type={'list'} />
                      ))}
                    </SectionCarousel.Skeletons>
                  </SectionCarousel>
                ) : (
                  <div className={s.restaurants}>
                    <SectionTitle title={'В этой статье'} className={s['restaurants-title']} skeleton={!news} />

                    <div className={s['restaurants-list']}>
                      {news
                        ? restaurants?.map((item, index) => (
                            <RestCard
                              key={item.id}
                              data={item}
                              sizes={'L ML'}
                              type={'list'}
                              analytic={{
                                advertCardPlaceType: null,
                                section_name: `Ресторан из статьи`,
                                section_index: index,
                              }}
                            />
                          ))
                        : Array.from(Array(5).keys()).map((item, index) => (
                            <RestCardSkeleton key={index} sizes={'L ML'} type={'list'} />
                          ))}
                    </div>
                  </div>
                ))}

              {/*@ts-ignore*/}
            </ContainerTwoColumns.Main>

            {/*// @ts-ignore*/}
            <ContainerTwoColumns.Aside>
              {/*@ts-ignore*/}
              {!isMobile && !isTablet && <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />}
              {/*@ts-ignore*/}
              {!isMobile && !isTablet && <AdvertTemplate type={'240x400_2nd'} container={AdvertSidebarSticky} />}
              {/*// @ts-ignore*/}
            </ContainerTwoColumns.Aside>
          </ContainerTwoColumns>

          {(isMobile || isTablet) && <AdvertTemplate type={'Footer'} />}

          {isShowOthersNews && (
            <ContainerLongLeft>
              <section className={s['news-mini-slider-roll']}>
                <h2 className='visually-hidden'>Другие новости</h2>
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Другие новости'}
                  link={`/${stateGlobal.currentCity.slug}/restaurant-news/`}
                  skeleton={!news}>
                  <SectionCarousel.Items>
                    {others?.map((news, index) => (
                      <NewsCard
                        sizes={(index + 1) % 3 === 0 ? 'L2x1 L2x1 L2x1 1x1' : 'L1x1 L1x1 L1x1 1x1'}
                        key={news.id}
                        data={news}
                        analytic={{
                          section_name: `Другие новости`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <NewsCardSkeleton
                        key={idx}
                        sizes={(idx + 1) % 3 === 0 ? 'L2x1 L2x1 L2x1 1x1' : 'L1x1 L1x1 L1x1 1x1'}
                      />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              </section>
            </ContainerLongLeft>
          )}

          {/*@ts-ignore*/}
          <DailyCarousel container={ContainerLongLeft} />
        </main>
      ) : (
        <div className={s.plug}></div>
      )}
    </ErrorBoundary>
  )
}

export default NewsItem
