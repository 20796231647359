import React, { FC } from 'react'
import Script from 'next/script'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = GITLAB_STATUS === '0'

const Mindbox: FC = () => {
  return (
    <>
      <Script id='show-banner'>
        {`
          mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
          mindbox.queue = mindbox.queue || [];
          mindbox('create', {endpointId: 'Afisha-Restaurants'});
        `}
      </Script>

      {IS_PROD && <Script src={'https://api.mindbox.ru/scripts/v1/tracker.js'} strategy={'lazyOnload'} />}
    </>
  )
}

export default Mindbox
