// First, create the thunk
import { AnyAction } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { globalStore } from '@app/model/store'
import { setUserData } from '@app/model/reduce'

export const updateRestLike = (restId) => (dispatch: Dispatch<AnyAction>) => {
  const store = globalStore.getState()

  try {
    const isRestaurantInFavorites = store.global.user.data.favorites_objects.some((rest) => rest.id === restId)
    isRestaurantInFavorites
      ? API.like_rest_remove_create({ path: restId.toString() })
          .then(() => API.user_me().then((el) => dispatch(setUserData(el))))
          .catch(() => new Error())
      : API.like_rest_add_create({ path: restId.toString(), body: { is_positive: true } })
          .then(() => API.user_me().then((el) => dispatch(setUserData(el))))
          .catch(() => new Error())
  } catch (e) {
    // @ts-ignore
    dispatch(addTemporaryNotice('Что-то пошло не так, попробуйте позже'))
  }
}
