export const apiPublicMock = {
  booking_create: {},

  booking_get_slots: {},

  booking_discount: {},

  booking_restaurants_list: {},

  city_list: {},

  city_read: {},

  like_rest_add_create: {},

  like_rest_remove_create: {},

  metro_list: {},

  news_list: {},

  news_read: {},

  photo_list: {},

  restaurant_list: {},

  restaurant_read: {},

  restaurant_nearest_rests: {},

  restaurant_popular_rests: {},

  restaurant_similar_rests: {},

  reviews_list: {},

  reviews_create: {},

  reviews_partial_update: {},

  reviews_update: {},

  reviews_delete: {},

  selection_list: {},

  selection_read: {},

  selection_restaurants: {},

  seo_read: {},

  tag_list: {},

  user_logout: {},

  user_me: {
    afisha_id: 7482887,
    email: 'alivedd@icloud.com',
    photo: 'https://avatars.rambler.ru/get/{id_a}/default?{id_b}',
    gender: 1,
    username: 'user_mail@mail.ru',
    first_name: 'Rick',
    last_name: 'Astley',
    stf: true,
    spr: true,

    favorites_objects: [
      {
        id: 421793,
        abs_url: '/msk/restaurant/ryba-moia-421793/',
        name: 'Рыба моя',
        url_slug: 'ryba-moia-421793',
      },
      {
        id: 28501,
        abs_url: '/msk/restaurant/elardzhi-28501/',
        name: 'Эларджи',
        url_slug: 'elardzhi-28501',
      },
      {
        id: 20549,
        abs_url: '/msk/restaurant/cantinetta-antinori-20549/',
        name: 'Cantinetta Antinori',
        url_slug: 'cantinetta-antinori-20549',
      },
      {
        id: 25457,
        abs_url: '/msk/restaurant/o2-restaurant-25457/',
        name: 'O2 Restaurant',
        url_slug: 'o2-restaurant-25457',
      },
      {
        id: 259661,
        abs_url: '/msk/restaurant/porto-mikonos-259661/',
        name: 'Порто Миконос',
        url_slug: 'porto-mikonos-259661',
      },
      {
        id: 495737,
        abs_url: '/msk/restaurant/kappo-hiroki-arakava-495737/',
        name: 'Каппо Хироки Аракава',
        url_slug: 'kappo-hiroki-arakava-495737',
      },
      {
        id: 486848,
        abs_url: '/msk/restaurant/birds-486848/',
        name: 'Birds',
        url_slug: 'birds-486848',
      },
      {
        id: 507909,
        abs_url: '/msk/restaurant/ruki-vverh-507909/',
        name: 'Руки вверх!',
        url_slug: 'ruki-vverh-507909',
      },
    ],

    owner_rests: [45826],

    owner_perms: {
      has_booking_access: true,
      has_special_access: true,
      has_description_access: true,
    },
  },

  variety_list: {},

  video_list: {},

  send_mistake: {},

  send_new_rest: {},
}
