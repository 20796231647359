/**
 * @description - // TODO: необходимо добавить описание функций (особенности, описание, назначение, итп)
 * @description - mappingAPI
 * @description - mappingAPIowner
 * @description - mappingAPIlk
 */

import { getMappingRestaurantListSort } from './helpers'

import { getMappingCityRead } from '@shared/api/middleware/mappingAPI/city/city'
import { mappingNewsItem } from '@shared/api/middleware/mappingAPI/news/news'
import { mappingPagination } from '@shared/api/middleware/mappingAPI/pagination/pagination'
import { mappingPhoto } from '@shared/api/middleware/mappingAPI/photo/photo'
import { mappingRestaurantItem } from '@shared/api/middleware/mappingAPI/restaurant/restaurant'
import { mappingReviewItem } from '@shared/api/middleware/mappingAPI/review/review'
import { mappingSelectionItem } from '@shared/api/middleware/mappingAPI/selection/selection'
import { mappingSEO } from '@shared/api/middleware/mappingAPI/seo/seo'
import { Metro, Tag } from '@shared/api/types/swaggerTypes'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'
import { mappingOwnerReviewItem } from '@shared/api/middleware/mappingAPI/owner/review/review'
import { mappingTagItem } from '@shared/api/middleware/mappingAPI/tag/tag'

export const mappingAPI = {
  booking_create: (request) => request,
  booking_restaurants_list: (request) => request,
  booking_get_slots: (request) => request,
  booking_discount: (request) => request,
  city_list: (request) => request,
  city_read: (request) =>
    request.then((res) => ({
      ...getMappingCityRead(res),
      feature_restaurants: getMappingRestaurantListSort(res.feature_restaurants),
    })),
  like_rest_add_create: (request) => request,
  like_rest_remove_create: (request) => request,
  metro_list: (request) => {
    return request.then((res): { pagination: TypeMappingPaginationOutput; results: Metro[] } => ({
      pagination: mappingPagination(res),
      results: res?.results,
    }))
  },
  news_list: (request) => {
    return request.then((res) => ({
      pagination: mappingPagination(res),
      results: res?.results?.map((news) => mappingNewsItem(news)),
    }))
  },
  news_read: (request) => request.then((res) => mappingNewsItem(res)),
  photo_list: (request) => {
    return request.then((res) => ({
      ...res,
      MAPPING: {
        pagination: mappingPagination(res),
        results: res?.results?.map((photo) => mappingPhoto(photo)),
      },
    }))
  },
  restaurant_list: (request) => {
    return request.then((res) => ({
      pagination: mappingPagination(res),
      results: getMappingRestaurantListSort(res?.results),
    }))
  },
  restaurant_read: (request) => request.then((res) => mappingRestaurantItem(res)),
  restaurant_nearest_rests: (request) => request.then((res) => getMappingRestaurantListSort(res)),
  restaurant_popular_rests: (request) => request.then((res) => getMappingRestaurantListSort(res)),
  restaurant_similar_rests: (request) => request.then((res) => getMappingRestaurantListSort(res)),
  reviews_list: (request) => {
    return request.then((res) => ({
      pagination: {
        ...mappingPagination(res),
        positive: res.positive_count,
        negative: res.negative_count,
      },
      results: res?.results?.map((review) => mappingReviewItem(review)),
    }))
  },
  reviews_create: (request) => request,
  reviews_partial_update: (request) => request,
  reviews_update: (request) => request,
  reviews_delete: (request) => request,
  selection_list: (request) => {
    return request.then((res) => ({
      pagination: mappingPagination(res),
      results: res?.results?.map((selection) => mappingSelectionItem(selection)),
    }))
  },
  selection_read: (request) => request.then((res) => mappingSelectionItem(res)),
  selection_restaurants: (request) => {
    return request.then((res) => ({
      pagination: mappingPagination(res),
      results: res?.results?.map((selection) => mappingRestaurantItem(selection)),
    }))
  },
  seo_read: (request) => request.then((res) => mappingSEO(res)),
  tag_list: (request) => {
    return request.then((res): { pagination: TypeMappingPaginationOutput; results: Tag[] } => ({
      pagination: mappingPagination(res),
      results: res?.results?.map((tag) => mappingTagItem(tag)),
    }))
  },
  user_logout: (request) => request,
  user_me: (request) => request,
  variety_list: (request) => request,
  video_list: (request) => request,
  send_mistake: (request) => request,
}

export const mappingAPIowner = {
  api_files_list: (request) => request,
  api_files_create: (request) => request,
  api_files_read: (request) => request,
  api_files_delete: (request) => request,

  r_read: (request) => request,
  r_info: (request) => request,
  r_menu_partial_update: (request) => request,
  r_menu_item_create: (request) => request,
  r_menu_item_partial_update: (request) => request,
  r_menu_item_delete: (request) => request,
  r_news_partial_update: (request) => request,
  r_special_partial_update: (request) => request,

  owner_reviews_list: (request) => {
    return request.then((res) => ({
      pagination: {
        ...mappingPagination(res),
        positive: res.positive_count,
        negative: res.negative_count,
        unanswered: res.without_owner_comment_count,
      },
      results: res?.results?.map((review) => mappingOwnerReviewItem(review)),
    }))
  },

  owner_reviews_read: (request) => request,
  owner_reviews_partial_update: (request) => request,
}

// deprecated
export const mappingAPIlk = {
  deprecated_booking_list: (request) => request,
  deprecated_booking_read: (request) => request,
  deprecated_booking_partial_update: (request) => request,
  deprecated_booking_guest_not_come: (request) => request,
  deprecated_booking_set_total: (request) => request,
}
