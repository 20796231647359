import { globalStore } from '@app/model/store'
import { addTemporaryNotice } from '@widgets/Common/Notices'

/**
 * @function middlewareError
 * @description isThrowExeption - для данного метода отключена до рефакторинга аутентификации
 *    сейчас ошибка срабатывает постоянно, на деле должна выскакивать,
 *    только если действительно существует проблема,
 *    сейчас же мы просто неверно выполняем запрос:
 *    пытаемся запросить данные о пользователе, которого еще нет
 */
export const middlewareError = (isShowToUser: boolean, textError: string, isThrowExeption = true) => {
  const dispatch = globalStore.dispatch
  // @ts-ignore
  isShowToUser && dispatch(addTemporaryNotice(textError))
  console.error(textError)

  if (isThrowExeption) {
    throw new Error(textError)
  }
}

/**
 * @constant textErrorsApiPublic
 */
export const textErrorsApiPublic = {
  booking_create: 'Ошибка отправки данных',
  booking_get_slots: 'Ошибка загрузки слотов онлайн-бронирования',
  booking_discount: 'Не удалось проверить скидку, попробуйте позже',
  booking_restaurants_list: 'Ошибка загрузки списков ресторанов с бронированием',
  city_list: 'Ошибка загрузки списка городов',
  city_read: 'Ошибка загрузки данных о городе',
  like_rest_add_create: 'Ошибка отправки данных',
  like_rest_remove_create: 'Ошибка отправки данных',
  metro_list: 'Ошибка загрузки списка метро',
  news_list: 'Ошибка загрузки списка новостей',
  news_read: 'Ошибка загрузки новости',
  photo_list: 'Ошибка загрузки галереи',
  restaurant_list: 'Ошибка загрузки списка ресторанов',
  restaurant_read: 'Ошибка загрузки данных о ресторане',
  restaurant_nearest_rests: 'Ошибка загрузки списка ближайших ресторанов',
  restaurant_popular_rests: 'Ошибка загрузки списка популярных ресторанов',
  restaurant_similar_rests: 'Ошибка загрузки списка похожих ресторанов',
  reviews_list: 'Ошибка загрузки отзывов',
  reviews_create: 'Ошибка отправки отзыва',
  reviews_partial_update: 'Ошибка отправки обновленного отзыва',
  reviews_update: 'Ошибка отправки обновленного отзыва',
  reviews_delete: 'Ошибка отправки удаления отзыва',
  selection_list: 'Ошибка загрузки списка подборок',
  selection_read: 'Ошибка загрузки данных о подборке',
  selection_restaurants: 'Ошибка загрузки списка ресторанов подборки',
  seo_read: 'Ошибка загрузки данных сео',
  tag_list: 'Ошибка загрузки тегов',
  user_logout: 'Ошибка попытки выхода из личного кабинета',
  user_me: 'Ошибка получения данных о пользователе',
  variety_list: 'Ошибка загрузки фильтров',
  video_list: 'Ошибка загрузки видео',
  send_mistake: 'Что-то пошло не так. Форма не отправлена.\n' + 'Попробуйте позже еще раз.',
  send_new_rest: 'Что-то пошло не так. Форма не отправлена.\n' + 'Попробуйте позже еще раз.',
}

/**
 * @constant textErrorsApiOwner
 */
export const textErrorsApiOwner = {
  api_files_list: 'Ошибка получения списка файлов',
  api_files_create: 'Ошибка создания файла',
  api_files_read: 'Ошибка чтения файлов',
  api_files_delete: 'Ошибка удаления файлов',
  r_read: 'Ошибка чтения ресторана',
  r_info: 'Ошибка получения информации о ресторане',
  r_menu_partial_update: 'Ошибка обновления меню',
  r_menu_item_create: 'Ошибка создания элемента меню',
  r_menu_item_partial_update: 'Ошибка обновления элемента меню',
  r_menu_item_delete: 'Ошибка удаления элемента меню',
  r_news_partial_update: 'Ошибка обновления материала: новость',
  r_special_partial_update: 'Ошибка обновления материала: спецпредложение',
  owner_reviews_list: 'Ошибка получения списка отзывов',
  owner_reviews_read: 'Ошибка получения отзыва',
  owner_reviews_partial_update: 'Ошибка обновления отзыва',
}

/**
 * @constant textErrorsApiLk
 * @deprecated
 */
export const textErrorsApiLk = {
  deprecated_booking_list: 'Ошибка отображения списка бронирований',
  deprecated_booking_read: 'Ошибка отображения бронирования',
  deprecated_booking_partial_update: 'Ошибка редактирования бронирования',
  deprecated_booking_guest_not_come: 'Ошибка смены статуса',
  deprecated_booking_set_total: 'Ошибка смены суммы',
}
