import { ApolloClient, InMemoryCache } from '@apollo/client'
import gql from 'graphql-tag'
import { getResizedLink } from '@widgets/Common/Daily/DailyCarousel/helpers/getImageURL'
import { getFullEntrySlug } from '@widgets/Common/Daily/DailyCarousel/helpers/getEntryURL'

export const fetchDaily = () =>
  new Promise((resolve, reject) => {
    try {
      // ! TODO: смержить работу с разными граф-сервисами, search: https://graph.afisha.ru/graphql
      const client = new ApolloClient({
        uri: 'https://daily.afisha.ru/public/api/graphql/',
        cache: new InMemoryCache(),
      })

      client
        .query({
          query: gql`
            query GetDaily {
              recentArticles(filter: { rubric: ["Еда"], theme: ["Рестораны"], limit: 20 }) {
                ... on Entry {
                  id
                  title
                  slug
                  photoModifications {
                    crop {
                      size {
                        height
                        width
                      }
                    }
                    photoPath
                    place
                  }
                  rubric {
                    slug
                  }
                }
              }
            }
          `,
        })
        .then((res) => {
          const result = res.data.recentArticles.map((entry) => ({
            id: entry.id,
            title: entry.title,
            url: getFullEntrySlug(entry.id, entry.slug, entry.rubric.slug),
            img: entry.photoModifications.map((photo) =>
              // @ts-ignore
              getResizedLink({
                photoModification: photo,
                width: photo.crop.size.width || '-',
                height: photo.crop.size.height || '-',
              }),
            ),
          }))
          resolve(result)
        })
        .catch((e) => {
          reject()
        })
    } catch (e) {
      reject()
    }
  })
