import React, { FC, memo, ReactNode, useEffect, useState } from 'react'
import s from './RestFavorite.module.sass'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { updateRestLike } from '@features/Restaurant/RestFavorite/model/createActions'
import { ErrorBoundary } from '../../../6_shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'

interface Props {
  restId: number
  children: ReactNode
  analytic: {
    data: TypeMappingRestOutput
    other: PickAnalytic<'section_name'>
  }
}

const RestFavorite: FC<Props> = ({ restId, analytic, children }) => {
  const dispatch = useAppDispatch()
  const stateUser = useAppSelector((state) => state.global.user.data)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    stateUser && setIsActive(stateUser.favorites_objects.some((rest) => rest.id === restId))
  }, [restId, stateUser])

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const analyticMethod = isActive
      ? EGG.entity.restaurant.click_remove_favorite
      : EGG.entity.restaurant.click_add_favorite
    analyticMethod(analytic.data, analytic.other)

    middlewareAuthorized(() => {
      setIsActive(!isActive)
      dispatch(updateRestLike(restId))
    })
  }

  return (
    <ErrorBoundary>
      <div className={s.button} onClick={handleClick} data-active={isActive}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

export default memo(RestFavorite)
