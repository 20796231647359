import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { generateDatesOptions, generateTimeOptions } from '@widgets/Restaurant/Booking/lib/booking-datetime'

import { TypeBookingAlert, TypeBookingState, TypeRestaurant } from '@widgets/Restaurant/Booking/model/types'

import { TypeDay, TypeSchedules, TypeTime } from '@widgets/Restaurant/Booking/types'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { isPhonePatternValid } from '@widgets/Restaurant/Booking/lib/helper'

const initialState: TypeBookingState = {
  isOpenModal: false,
  isOpenScreenForm: false,
  isOpenScreenConfirm: false,
  isOpenScreenSuccess: false,
  source: '',
  restaurantSource: {
    id: null,
    name: '',
    slug: '',
    schedule: {},
    couponCode: '',
    couponValue: '',
    alerts: null,
    remarked: {
      active: false,
    },
    discounts: {
      remarked: null,
      tastyclub: null,
    },
    deposit: {
      active: false,
      text: null,
    },
  },
  form: {
    schedule: null,
    isErrorsVisible: false,
    guestsCountField: {
      min: 1,
      max: 50,
      value: 2,
      valid: true,
    },
    nameField: { id: 'name', value: '', valid: false },
    phoneField: { id: 'phone', value: '', valid: false },
    commentField: { id: 'comment', value: '', valid: true },
    dateField: {
      // первый ближайший день в который ресторан открыт
      default: null,
      selected: null,
      options: [],
      valid: false,
    },
    timeField: {
      default: [],
      selected: null,
      options: [],
      valid: false,
    },
    checkDiscountLoading: false,
    onlineSlotsLoading: false,
  },
}

const restaurantBooking = createSlice({
  name: 'notices',
  initialState,

  reducers: {
    closeBooking(state) {
      if (state.isOpenScreenSuccess || state.isOpenScreenConfirm) return initialState
      else {
        state.isOpenScreenConfirm = true
        state.isOpenScreenForm = false
      }
    },

    setIsOpenScreenConfirm(state, action: PayloadAction<boolean>) {
      state.isOpenScreenConfirm = action.payload
      state.isOpenScreenForm = !action.payload
    },

    setIsOpenScreenSuccess(state, action: PayloadAction<boolean>) {
      state.isOpenScreenSuccess = action.payload
      state.isOpenScreenForm = !action.payload
    },

    openModal(state, action: PayloadAction<{ restaurant: TypeMappingRestOutput }>) {
      const actionRest = action.payload.restaurant

      const getAlerts = () => {
        const results: TypeBookingAlert[] = []

        if (actionRest.booking.afisha.alert.active && actionRest.booking.afisha.alert.message) {
          results.push({
            id: 1,
            type: 'default',
            visible: true,
            disabled: false,
            color: '#E0EEFF',
            text: actionRest.booking.afisha.alert.message,
          })
        }
        if (actionRest.discounts.remarked) {
          results.push({
            id: 2,
            type: 'discount',
            visible: true,
            disabled: false,
            color: '#D2FF98',
            aside: `-${actionRest.discounts.remarked}₽`,
            text: `Скидка на основное меню при первом бронировании на сайте Афиша Рестораны`,
            conclusion: `скидка ${getValueDiscount(actionRest.discounts.remarked)}₽ при первом бронировании`,
          })
        }
        if (actionRest.deposit.active && actionRest.deposit.text) {
          results.push({
            id: 3,
            type: 'deposit',
            visible: true,
            disabled: false,
            color: '#E0EEFF',
            text: actionRest.deposit.text,
            conclusion: 'предварительно оплачивается депозит',
          })
        }

        return results
      }

      const getValueDiscount = (string) => {
        try {
          return string ? string.match(/\d+/i)[0] : null
        } catch (e) {
          return null
        }
      }

      state.isOpenModal = true
      state.isOpenScreenForm = true
      state.restaurantSource = {
        id: actionRest.id,
        name: actionRest.name,
        slug: actionRest.url.slug,
        schedule: actionRest.schedule.week,
        couponCode: null,
        couponValue: null,
        alerts: getAlerts(),
        remarked: actionRest.booking.afisha.remarked,
        discounts: actionRest.discounts,
        deposit: actionRest.deposit,
      }
    },

    setAlerts(state, action: PayloadAction<TypeBookingAlert[]>) {
      state.restaurantSource.alerts = action.payload
    },

    setDisabledAlertItem(state, action: PayloadAction<{ type: string; disabled: boolean }>) {
      if (!state.restaurantSource.alerts.length) return
      const findAlertIndex = state.restaurantSource.alerts.findIndex((item) => item.type === action.payload.type)

      if (findAlertIndex == -1) return
      state.restaurantSource.alerts[findAlertIndex].disabled = action.payload.disabled
    },

    setCheckDiscountLoading(state, action: PayloadAction<boolean>) {
      state.form.checkDiscountLoading = action.payload
    },

    setNameFieldValue(state, action: PayloadAction<string>) {
      let newValue = action.payload || ''

      if (newValue.indexOf('\r') !== -1) {
        newValue = newValue.replace(/\r?\n|\r/g, '')
      } else if (newValue.indexOf('\n') !== -1) {
        newValue = newValue.replace(/\r?\n|\r/g, '')
      }

      const isNamePatternValid = (nameValue: string, isTyping: boolean) => {
        const thisValue = isTyping ? nameValue : nameValue.trim()
        return !!thisValue && thisValue !== '' && thisValue.length >= 2
      }

      state.form.nameField.value = newValue
      state.form.nameField.valid = isNamePatternValid(newValue, true)
    },

    setPhoneFieldValue(state, action) {
      const newValue = action.payload || ''

      state.form.phoneField.value = newValue
      state.form.phoneField.valid = isPhonePatternValid(newValue)
    },

    setCommentFieldValue(state, action: PayloadAction<string>) {
      state.form.commentField.value = action.payload || ''
      state.form.commentField.valid = true
    },

    setIsErrorsVisible(state) {
      state.form.isErrorsVisible = true
    },

    setGuestsCountValue(state, action: PayloadAction<number>) {
      const newValue = action.payload
      const { min, max } = state.form.guestsCountField
      if (newValue <= max && newValue >= min) {
        state.form.guestsCountField.value = action.payload
        state.form.guestsCountField.valid = true
      }
    },

    setSchedule(state, action: PayloadAction<TypeSchedules>) {
      state.form.schedule = action.payload
    },

    setDatesOptions(state, action: PayloadAction<undefined | TypeDay[]>) {
      const { schedule } = state.form
      if (!action.payload && schedule) {
        state.form.dateField.options = generateDatesOptions(schedule)
      }
      if (action.payload) {
        state.form.dateField.options = action.payload
      }
    },

    setDefaultDay(state) {
      const { options } = state.form.dateField
      if (options) {
        const openDaysArr = options.filter((day: TypeDay) => day.isRestOpened === 'opened')
        state.form.dateField.default = openDaysArr[0]
      }
    },

    setSelectedDate(state, action: PayloadAction<undefined | TypeDay>) {
      if (!action.payload) {
        state.form.dateField.selected = null
        state.form.dateField.valid = false
      }
      if (action.payload) {
        state.form.dateField.selected = action.payload
        state.form.dateField.valid = true
      }
    },

    setDefaultTime(state) {
      if (state.form.dateField.default) {
        state.form.timeField.default = generateTimeOptions(state.form.dateField.default)
      }
    },

    setTimesOptions(state, action: PayloadAction<undefined | TypeDay>) {
      if (!action.payload && state.form.dateField.default) {
        state.form.timeField.options = generateTimeOptions(state.form.dateField.default)
      }
      if (action.payload) {
        state.form.timeField.options = generateTimeOptions(action.payload)
      }
    },

    setSelectedTime(state, action: PayloadAction<undefined | TypeTime>) {
      const isTimePatternValid = (timeValue: string | undefined) => {
        if (!timeValue) return false
        else return !!timeValue && timeValue !== ''
      }

      if (!action.payload) {
        state.form.timeField.selected = null
        state.form.timeField.valid = false
      } else {
        state.form.timeField.selected = action.payload
        state.form.timeField.valid = isTimePatternValid(state.form.timeField.options[0]?.value)
      }
    },

    setOnlineSlotsLoading(state, action) {
      if (action.payload) {
        state.form.onlineSlotsLoading = action.payload
      } else {
        state.form.onlineSlotsLoading = false
      }
    },

    // Special reducer for hydrating the state
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.notices,
        }
      },
    },
  },
})

export const {
  closeBooking,
  openModal,
  setIsOpenScreenConfirm,
  setIsOpenScreenSuccess,
  setIsErrorsVisible,
  setGuestsCountValue,
  setSchedule,
  setDatesOptions,
  setDefaultDay,
  setDefaultTime,
  setTimesOptions,
  setSelectedDate,
  setSelectedTime,
  setOnlineSlotsLoading,
  setNameFieldValue,
  setPhoneFieldValue,
  setCommentFieldValue,
  setAlerts,
  setDisabledAlertItem,
  setCheckDiscountLoading,
} = restaurantBooking.actions

export default restaurantBooking.reducer
