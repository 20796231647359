import React, { FC } from 'react'
import * as Sentry from '@sentry/browser'

// class ErrorBoundary extends React.PureComponent {
//   componentDidCatch(error: any, errorInfo: any) {
//     Sentry.withScope((scope: any) => {
//       Object.keys(errorInfo).forEach((key) => {
//         scope.setExtra(key, errorInfo[key])
//       })
//
//       Sentry.captureException(error)
//     })
//
//     if (super.componentDidCatch) super.componentDidCatch(error, errorInfo)
//   }
//   render() {
//     // @ts-ignore
//     return this.props.children
//   }
// }

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    Sentry.withScope((scope: any) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return <></>
    }
    // @ts-ignore
    return this.props.children
  }
}

export default ErrorBoundary
