import React, { FC, memo, ReactNode, useEffect, useState } from 'react'
import s from './DailyCarousel.module.sass'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { DailyCard } from '@widgets/Common/Daily/DailyCard'
import { RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { fetchDaily } from '@widgets/Common/Daily/DailyCarousel/gqlDaily'
import { TypeDailyArticle } from '@widgets/Common/Daily/DailyCarousel/types/types'

interface Props {
  container?: ReactNode
  className?: string
}

// @ts-ignore
const DefaultContainer: FC = ({ children }) => {
  return <div>{children}</div>
}

const DailyCarousel: FC<Props> = ({ container, className = '' }) => {
  const [arrArticle, setArrArticle] = useState<TypeDailyArticle[]>()
  const [loading, setLoading] = useState(true)
  const Container = container || DefaultContainer
  const isVisible = (loading && !arrArticle) || !!arrArticle?.length

  useEffect(() => {
    fetchDaily()
      .then((data) => setArrArticle(data as TypeDailyArticle[]))
      .finally(() => setLoading(() => false))
  }, [])

  return (
    isVisible && (
      //@ts-ignore
      <Container classNameContainer={className}>
        <SectionCarousel
          title={'Афиша Daily'}
          link={'https://daily.afisha.ru/'}
          skeleton={loading}
          className={s.carousel}>
          <SectionCarousel.Skeletons>
            {Array.from(Array(4).keys()).map((i, idx) => (
              <RestCardSkeleton key={idx} sizes={'L ML'} />
            ))}
          </SectionCarousel.Skeletons>
          <SectionCarousel.Items>
            {arrArticle?.map((article) => (
              <DailyCard key={article.id} title={article.title} url={article.url} img={article.img[0]} />
            ))}
          </SectionCarousel.Items>
        </SectionCarousel>
      </Container>
    )
  )
}

export default memo(DailyCarousel)
