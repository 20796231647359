import s from './FloatingMenu.module.sass'
import React, { FC, memo, useState } from 'react'

import { openBooking } from '@widgets/Restaurant/Booking'
import { useAppDispatch, useAppSelector } from '@app/model/store'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { getShortTextSchedule } from '@shared/lib/helpers/schedule'
import { useIntersection } from '@shared/lib/hooks/useIntersection'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Button } from '@shared/ui/Actions/Button'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'

interface Props {
  trackedRef: React.MutableRefObject<any>
  className?: string
}

const FloatingMenu: FC<Props> = ({ trackedRef, className = '' }) => {
  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false)
  const { isMobile } = useClientResponsive()

  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)
  const dispatch = useAppDispatch()
  const { id, name, booking, schedule, url, status, discounts, deposit } = stateRestaurant.data

  const whenOpens = getShortTextSchedule(stateRestaurant.data.schedule.week)
  const isShowBooking = [0].includes(status.code) && (booking.afisha.active || booking.tomesto.active)

  useIntersection(
    trackedRef,
    () => setIsFloatingMenuVisible(false),
    () => setIsFloatingMenuVisible(true),
  )

  const handleBooking = () => {
    if (booking.afisha.active) {
      const bookingAlert = booking.afisha.alert

      dispatch(openBooking(stateRestaurant.data as TypeMappingRestOutput))
    } else if (booking.tomesto.active) {
      window.open(booking.tomesto.url, '_blank')
    }

    EGG.entity.restaurant.click_booking(stateRestaurant.data as TypeMappingRestOutput, {
      section_name: 'page',
    })
  }

  return (
    <ErrorBoundary>
      <div className={`${s.sticky} ${className}`} data-visible={isFloatingMenuVisible}>
        {isMobile ? (
          <div className={s['buttons-wrapper']}>
            <Button
              sizes={'XL'}
              mode={'black'}
              className={s['mobile-widget']}
              onClick={() => handleBooking()}
              disabled={!isShowBooking}>
              Забронировать столик
            </Button>
            {discounts.remarked && (
              <PromoBadgeCTA sizes={'XL'} className={s['promo-badge']}>
                -{discounts.remarked}₽
              </PromoBadgeCTA>
            )}
          </div>
        ) : (
          <div className={s['desktop-widget']}>
            <div className={s['left-side']}>
              {name && (
                <Text tag={'span'} sizes={'L'}>
                  {name}
                </Text>
              )}

              <Text tag={'span'} sizes={'M'} className={s['schedule-data']}>
                {whenOpens || 'Время работы не указано'}
              </Text>
            </div>

            <div className={s['buttons-wrapper']}>
              {discounts.remarked && (
                <PromoBadgeCTA sizes={'XL'} className={s['promo-badge']}>
                  -{discounts.remarked}₽
                </PromoBadgeCTA>
              )}
              <button className={s.button} onClick={() => handleBooking()} disabled={!isShowBooking} type='button'>
                <Text sizes={'M'} tag={'span'}>
                  Забронировать столик
                </Text>
              </button>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default memo(FloatingMenu)
