import React, { FC, ReactNode, useMemo } from 'react'
import s from './DefaultTag.module.sass'
import { Button } from '@shared/ui/Actions/Button'
import { ErrorBoundary } from '../../../../../lib/components/ErrorBoundary'

interface Props {
  onClick?: () => void
  className?: string
  active?: boolean
  children: ReactNode
  counter?: number
}

const DefaultTag: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  onClick,
  className = '',
  children,
  counter,
  active = false,
  ...props
}) => {
  return (
    <ErrorBoundary>
      <Button
        sizes='M'
        mode='white-border'
        className={`${s.button} ${className}`}
        onClick={onClick}
        data-active={active}
        {...props}>
        {children}
        {counter && <span className={s.button__counter}>{counter}</span>}
      </Button>
    </ErrorBoundary>
  )
}

export default DefaultTag
