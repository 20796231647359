import s from './Template.module.sass'
import React, { FC, useRef } from 'react'
import Link from 'next/link'

import { Button } from '@shared/ui/Actions/Button'
import { CardTwoBlock } from '@shared/ui/Layout/CardTwoBlock'
import { EGG } from '@shared/api/analytics'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Rating } from '@shared/ui/Feedback/Rating'
import { Text } from '@shared/ui/Typography/Text'
import { useCurrentSize } from '@shared/lib/helpers/classes'

// @ts-ignore
import IconLike from '@icons/icon-like.svg'
// @ts-ignore
import IconChevron from '@icons/icon-chevron-medium.svg'
import { openBooking } from '@widgets/Restaurant/Booking'
import { useAppDispatch } from '@app/model/store'
import { RestFavorite } from '@features/Restaurant/RestFavorite'
import { TemplateCardProps } from '@widgets/Restaurant/ResturantCards/RestCard/type/TemplateCardProps'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import {
  sendAdvertisingRestAnalytic,
  TypeAdvertisingCardRestEvent,
} from '@shared/api/analytics/advertising/advertising'

const sizeTitle = {
  L: 'L',
  ML: 'ML',
  M: 'M',
  S: 'M',
}

const Template: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ data, analytic, sizes, send_analytic = true, className = '', children, ...props }) => {
  const [size] = useCurrentSize(sizes)
  const cardRef = useRef(null)
  const dispatch = useAppDispatch()

  const handleVisibleCard = () => {
    if (send_analytic) {
      EGG.entity.restaurant.card_view(data, {
        section_name: analytic.section_name,
        section_index: analytic.section_index,
      })
      handleAnalyticAdvert('visible')
    }
  }

  useIntersectionAnalytic(cardRef, handleVisibleCard)

  const handleAnalyticAdvert = (typeEvent: TypeAdvertisingCardRestEvent) => {
    if (data.categories.advert && analytic?.advertCardPlaceType && send_analytic) {
      sendAdvertisingRestAnalytic({
        type: typeEvent,
        city_id: data.address.city.id,
        rest_id: data.id,
        variety: analytic.advertCardPlaceType,
      })
    }
  }

  const handleClickCard = () => {
    if (send_analytic) {
      EGG.entity.restaurant.card_click(data, {
        section_name: analytic.section_name,
        section_index: analytic.section_index,
      })
      handleAnalyticAdvert('click')
    }
  }

  const RatePromoTemplate = () => {
    if (data.categories.advert) return <Rating isPromo sizes={'S'} className={size === 'S' ? s['promo-label'] : ''} />
    else if (data.feedback.rating && data.feedback.rating >= 4)
      return <Rating rating={Number(data.feedback.rating)} sizes={'S'} />
    else return <div></div>
  }

  const handleBooking = (event) => {
    event.stopPropagation()
    event.preventDefault()

    const dataAfisha = data.booking.afisha
    const dataTomesto = data.booking.tomesto

    if (dataAfisha.active) {
      dispatch(openBooking(data))
    } else if (dataTomesto.active) {
      window.open(dataTomesto.url, '_blank')
    }

    if (send_analytic) {
      EGG.entity.restaurant.click_booking(data, {
        section_name: analytic.section_name,
        section_index: analytic.section_index,
      })
    }
  }

  const thisImage = data.images.cover.rest1064x1064 || data.images.main

  // TODO: CardTwoBlock — choose better naming
  return (
    <ErrorBoundary>
      <CardTwoBlock
        sizes={sizes}
        href={data.url.path}
        className={`${s.card} ${className}`}
        onClick={handleClickCard}
        ref={cardRef}
        {...props}>
        {/*@ts-ignore*/}
        <CardTwoBlock.Img img={thisImage} restId={data.id} alt={''} classNameContent={s['img-content']}>
          <RatePromoTemplate />

          {data.categories.selection && <IconChevron className={s['afisha-selected']} />}

          <div className={s['img-bottom-wrapper']}>
            {(data.booking.afisha.active || data.booking.tomesto.active) && (
              <Button sizes={'S'} mode={'white'} onClick={handleBooking}>
                Забронировать
              </Button>
            )}

            <RestFavorite restId={data.id} analytic={{ data, other: { section_name: analytic?.section_name } }}>
              <IconButton
                sizes={'S'}
                mode={'white'}
                className={s.favorite}
                data-active={data.user.favourite}
                icon={IconLike}
              />
            </RestFavorite>
          </div>
          {/*@ts-ignore*/}
        </CardTwoBlock.Img>

        {/*@ts-ignore*/}
        <CardTwoBlock.Content data-promo={data.categories.advert} className={s.content} onClick={handleClickCard}>
          <Link href={data.url.path} scroll={false}>
            <Text tag={'span'} sizes={sizeTitle[size]}>
              {data.name}
            </Text>
          </Link>

          {children}
          {/*@ts-ignore*/}
        </CardTwoBlock.Content>
      </CardTwoBlock>
    </ErrorBoundary>
  )
}

export default Template
